import { useState } from "react";
import "alpinejs";
import { Link } from "react-router-dom";

const HeaderTop = () => {
  const [navbar, setNavbar] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    // <nav className="w-full bg-white shadow">
    //   <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
    //     <div>
    //       <div className="flex items-center justify-between py-3 md:py-5 md:block">
    //         <a href="javascript:void(0)">
    //           <h2 className="md:text-4xl text-2xl font-bold">
    //             <span className="text-lightBlue">J</span>aanavi{" "}
    //             <span className="text-lightBlue">C</span>onstructions
    //           </h2>
    //         </a>
    //         <div className="md:hidden">
    //           <button
    //             className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
    //             onClick={() => setNavbar(!navbar)}
    //           >
    //             {navbar ? (
    //               <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 className="w-6 h-6"
    //                 viewBox="0 0 20 20"
    //                 fill="currentColor"
    //               >
    //                 <path
    //                   fillRule="evenodd"
    //                   d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
    //                   clipRule="evenodd"
    //                 />
    //               </svg>
    //             ) : (
    //               <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 className="w-6 h-6"
    //                 fill="none"
    //                 viewBox="0 0 24 24"
    //                 stroke="currentColor"
    //                 strokeWidth={2}
    //               >
    //                 <path
    //                   strokeLinecap="round"
    //                   strokeLinejoin="round"
    //                   d="M4 6h16M4 12h16M4 18h16"
    //                 />
    //               </svg>
    //             )}
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //     <div>
    //       <div
    //         className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
    //           navbar ? "block" : "hidden"
    //         }`}
    //       >
    //         <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
    //           <li className="text-gray-600 hover:text-blue-600">
    //             <a href="javascript:void(0)">Home</a>
    //           </li>
    //           <li className="text-gray-600 hover:text-blue-600">
    //             <a href="javascript:void(0)">Blog</a>
    //           </li>
    //           <li className="text-gray-600 hover:text-blue-600">
    //             <a href="javascript:void(0)">About US</a>
    //           </li>
    //           <li className="text-gray-600 hover:text-blue-600">
    //             <a href="javascript:void(0)">Contact US</a>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>
    //   </div>
    // </nav>
    <div className=" bg-white   bg-opacity-30 backdrop-filter backdrop-blur-xl shadow-lg">
      <div class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-full md:px-24 lg:px-8 ">
        <div class="relative flex items-center justify-between">
          {/* <a
            href="/"
            aria-label="Company"
            title="Company"
            class="inline-flex items-center"
          >
            <img
              src={require("../imgFiles/J_Cons.png")}
              className="w-16 text-deep-purple-accent-400"
              alt=""
            />
            <span class="ml-2 text-2xl sm:t font-bold tracking-wide text-gray-800 uppercase">
              Jaanavi <span className="text-reds"> Constructions</span>{" "}            
            </span>
              <span className="pb-2 non-italic lowercase text-sm">
                (Nature of Walls)
              </span>
  </a>*/}
          <a
            href="/"
            aria-label="Company"
            title="Company"
            class="inline-flex items-center"
          >
            <div class="flex items-center space-x-2">
              <img
                src={require("../imgFiles/J_Con.png")}
                alt=""
                class="object-cover object-center w-16 h-full rounded-full shadow-sm bg-coolGray-500 border-coolGray-700"
              />
              <div class="-space-y-1">
                <h2 class="text-sm font-semibold leading-none">
                  <span class="text-2xl  font-bold tracking-wide text-gray-800 uppercase">
                    Jaanavi <span className="text-reds"> Constructions</span>{" "}
                  </span>
                </h2>
                <span class="inline-block text-sm  font-semibold leading-none   text-coolGray-400">
                  Nature of walls
                </span>
              </div>
            </div>
          </a>
          <ul class="flex items-center hidden space-x-8 lg:flex">
            <li>
              <a
                href="/"
                aria-label="Our product"
                title="Our product"
                class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-lightBlue"
              >
                Home
              </a>
            </li>
            <li>
              <a
                aria-label="Our product"
                title="Our product"
                class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-lightBlue"
              >
                <Link to="/gallery">Gallery</Link>
              </a>
            </li>
            <li>
              <a
                aria-label="Our product"
                title="Our product"
                class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-lightBlue"
              >
                <Link to="/blog">Blog</Link>
              </a>
            </li>
          </ul>
          <div class="lg:hidden">
            <button
              aria-label="Open Menu"
              title="Open Menu"
              class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
              onClick={() => setIsMenuOpen(true)}
            >
              <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                />
                <path
                  fill="currentColor"
                  d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                />
                <path
                  fill="currentColor"
                  d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                />
              </svg>
            </button>

            {isMenuOpen && (
              <div class="absolute sm:mb-18 top-0 left-0 w-full ">
                <div class="p-5 bg-gradient-to-r   border border-black-800 backdrop:blur-3xl rounded shadow-2xl">
                  <div class="flex items-center justify-between mb-4">
                    <div>
                      <a
                        href="/"
                        aria-label="Company"
                        title="Company"
                        class="inline-flex items-center"
                      >
                        <div class="flex items-center space-x-2">
                          <img
                            src={require("../imgFiles/J_Con.png")}
                            alt=""
                            class="object-cover object-center w-16 h-full rounded-full shadow-sm bg-coolGray-500 border-coolGray-700"
                          />
                          <div class="-space-y-1">
                            <h2 class="text-sm font-semibold leading-none">
                              <span class="text-xl  font-bold tracking-wide text-gray-800 uppercase">
                                Jaanavi{" "}
                                <span className="text-reds">
                                  {" "}
                                  Constructions
                                </span>{" "}
                              </span>
                            </h2>
                            <span class="inline-block text-sm  font-semibold   leading-none   text-coolGray-400">
                              Nature of walls
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div>
                      <button
                        aria-label="Close Menu"
                        title="Close Menu"
                        class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline inline-flex items-center"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul class="space-y-4">
                      <li>
                        <a
                          href="/"
                          aria-label="Our product"
                          title="Our product"
                          class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Home
                        </a>
                      </li>
                      <li>
                        <a
                          aria-label="Our product"
                          title="Our product"
                          class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          <Link to="/gallery">Gallery</Link>
                        </a>
                      </li>
                      <li>
                        <a
                          aria-label="Our product"
                          title="Our product"
                          class="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          <Link to="/blog">Blog</Link>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
