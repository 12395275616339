import React from "react";

const BlogFile = () => {
  return (
    <div>
      <section class=" mt-4"></section>

      <div class="bg-gradient-to-r from-indigo-200 via-red-200 to-yellow-100 py-6 sm:py-8 lg:py-12">
        <div class="max-w-screen-xl px-4 md:px-8 mx-auto">
          <div class="grid md:grid-cols-2 gap-8 lg:gap-12">
            <div>
              <div class=" md:h-auto h-80 bg-gray-100 overflow-hidden rounded-lg shadow-lg">
                <img
                  src={require("../imgFiles/_G6A9800.jpg")}
                  loading="lazy"
                  alt="Photo by Martin Sanchez"
                  class="w-full h-full object-cover object-center"
                />
              </div>
            </div>

            <div class="md:pt-8">
              <p class="text-reds md:text-2xl text-2xl xl:text-2xl font-curs text-center md:text-left">
                Who we are
              </p>

              <h1 class="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left mb-4 md:mb-6">
                About Jaanavi Constructions
              </h1>

              <p class="text-gray-500 sm:text-lg mb-4 md:mb-6">
                J.C. will provide complete services from the drawing board to
                the final product with 100% customer satisfaction. we provide
                the best quality construction services. We respond to customer
                questions, doubts, and clarifications, with no time
                restrictions. Jaanavi Constructions always have great
                communication and business relationship with clients. No hidden
                charges are made for services. Our work will be done as per
                schedule. The project is run by NCB, a master control program.
                The project schedule will hand over one copy to the client so
                that they can track the schedule. Payments are also followed by
                schedule.
              </p>

              {/*   <h2 class="text-gray-800 text-xl sm:text-2xl font-semibold text-center md:text-left mb-2 md:mb-4">
                About us
  </h2> */}
              <p class="text-gray-500 sm:text-lg mb-6 md:mb-8">
                <span class="text-reds  ">JC construction </span>
                brings a wide range of capabilities to major infrastructure
                projects. Our integrated approach adaptability and dedication to
                quality over the past 4 decades have placed us at the forefront
                of infrastructure development across sectors. We strive to
                consistently maintain a pioneering spirit and take pride in our
                strong record of timely project delivery When it comes to
                building your home, we believe you should be in complete
                control, with access to a host of verified professionals,
                quality products, and complete safety.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogFile;
