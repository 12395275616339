import React from "react";
import emailjs from "emailjs-com";
import { useRef } from "react";

const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_yjbqa52",
        "template_v5v0dyo",
        form.current,
        "vU43Nvt0BnrGqyp8x"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div>
      <div class="max-w-screen-2xl px-4 md:px-8 mx-auto ">
        <h2 class="text-gray-800 text-2xl lg:text-3xl font-bold ">
          Reach out to us!
        </h2>
      </div>
      <section class="text-gray-600 body-font relative ">
        <div class="container px-5 py-6 mx-auto flex sm:flex-nowrap flex-wrap ">
          <div class="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  "<iframe width='100%' height='100%' class='absolute inset-0' frameborder='0' title='map' marginheight='0' marginwidth='0' scrolling='no' src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7772.0194994505!2d80.22913752720841!3d13.09856851309063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52644904af6069%3A0x82df834aedd6b21e!2sAyanavaram%2C%20Chennai%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1669745149676!5m2!1sen!2sin' style=' filter: grayscale(1) contrast(1.2) opacity(0.4);'/>",
              }}
            />
            <div class="bg-red-500 p-5  bg-opacity-5 backdrop-filter backdrop-blur-sm relative flex flex-wrap py-6 rounded shadow-md">
              <div class="lg:w-1/2 px-6">
                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  ADDRESS
                </h2>
                <p class="mt-1">
                  No. 96, Dr Ambedkar Nagar, 2nd Street, Ayanavaram, Chennai,
                  Tamil Nadu - 600023
                </p>
              </div>
              <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  EMAIL
                </h2>
                <a class="text-reds leading-relaxed">
                  jaanaviconstructions@gmail.com
                </a>
                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                  PHONE
                </h2>
                <p class="leading-relaxed">+91 70101 69622</p>

                <p class="leading-relaxed">+91 80722 11806</p>
              </div>
            </div>
          </div>
          <div class="lg:w-1/3 md:w-1/2  flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0 bg-white p-5 rounded-xl bg-opacity-30 backdrop-filter backdrop-blur-xl">
            <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">
              Contact Us
            </h2>
            <form ref={form} onSubmit={sendEmail}>
              <div class="relative mb-4">
                <label for="name" class="leading-7 text-sm text-gray-600">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  class="w-full bg-transparent rounded border border-gray-600 focus:border-reds focus:ring-2 focus:ring-reds text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div class="relative mb-4">
                <label for="email" class="leading-7 text-sm text-gray-600">
                  Email
                </label>

                <input
                  type="email"
                  id="email"
                  name="email"
                  class="w-full bg-transparent rounded border border-gray-600 focus:border-lightBlue focus:ring-2 focus:ring-reds text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div class="relative mb-4">
                <label for="message" class="leading-7 text-sm text-gray-600">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  class="w-full bg-transparent rounded border border-gray-600 focus:border-lightBlue focus:ring-2 focus:ring-reds h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                ></textarea>
              </div>
              <button
                type="submit"
                class="text-white bg-reds border-0 py-2 px-6 focus:outline-none hover:bg-lightBlue rounded text-lg"
              >
                Send
              </button>
            </form>
            <p class="text-xs text-gray-500 mt-3">
              Less than 12-hour response to your question.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
