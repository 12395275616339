import React from "react";

const HeroSection = () => {
  function handleScroll() {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: "smooth",
    });
  }
  return (
    <>
      {/*<div class="grid  place-items-center text-gray-800 text-2xl sm:text-2xl pt-4 md:text-3xl font-bold">
        Nature of walls
  </div>*/}

      <header>
        <div class="container px-6 py-6 mx-auto">
          <div class="items-center lg:flex">
            <div class="w-full lg:w-1/2">
              <div class="lg:max-w-xl">
                {/*<p class="text-reds md:text-2xl text-2xl xl:text-2xl font-curs  mb-2 md:mb-4">
                  Welcome to your home{" "}
</p>*/}

                <h1 class="text-black-800 text-3xl sm:text-4xl md:text-5xl font-bold mb-6 md:mb-8">
                  Building your visions. Creating reality.
                </h1>

                <p class="lg:w-2/1 text-gray-500 xl:text-lg leading-relaxed mb-6 md:mb-6">
                  Foundation of experience, strength of quality construction,
                  colors of innovative solutions, lights of new technology,
                  surety of transparent transactions & finally the touch of
                  emotion that converts it in to your dream home which adds
                  status to you.
                </p>

                <a
                  href="# "
                  onClick={handleScroll}
                  class="inline-block bg-reds hover:bg-lightBlue active:bg-lightBlue focus-visible:ring ring-indigo-300 text-white text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3"
                >
                  Reach Us
                </a>
              </div>
            </div>

            <div class="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2 hidden md:block">
              <img
                class="w-5/6 h-full lg:max-w-3xl"
                src={require("../imgFiles/cp1.png")}
                alt="Catalogue-pana.svg"
              />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeroSection;
