import React from "react";
import HeaderTop from "../HeaderTop/HeaderTop";
import HeroSection from "../HeroSection/HeroSection";
import ProfileFile from "../Profile/ProfileFile";
import Gallery from "../Gallery/Gallery";
import ContactUs from "../ContactUs/ContactUs";
import Footer from "../Footer/Footer";
const MainSite = () => {
  return (
    <div className="bg-gradient-to-r from-indigo-200 via-red-200 to-yellow-100">
      <HeroSection />
      <ProfileFile />
      <Gallery />
      <ContactUs />
    </div>
  );
};

export default MainSite;
