import React from "react";
import HeaderTop from "../HeaderTop/HeaderTop";
import BlogFile from "../BlogFile/BlogFile";
import Footer from "../Footer/Footer";
const BlogSite = () => {
  return (
    <div>
      <BlogFile />
    </div>
  );
};

export default BlogSite;
