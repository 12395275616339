import React from "react";
import HeaderTop from "../HeaderTop/HeaderTop";
import GalleryPics from "../GalleryPics/GalleryPics";
import Footer from "../Footer/Footer";
const GallerySite = () => {
  return (
    <div>
      <GalleryPics />
    </div>
  );
};

export default GallerySite;
