import React from "react";

const ProfileFile = () => {
  return (
    <div>
      {/*<section class="text-gray-600 body-font">
        <div class="container px-5 py-1 mx-auto flex flex-col">
          <div class="lg:w-4/6 mx-auto">
            <div class="flex flex-col sm:flex-row mt-10">
              <div class="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                <div class="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
                  <img src={require("../imgFiles/J_Cons.png")} alt="" />
                </div>
                <div class="flex flex-col items-center text-center justify-center">
                  <h2 class="font-medium title-font mt-4 text-gray-900 text-lg">
                    Our Mission
                  </h2>
                  <div class="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4"></div>
                  <p class="text-base">
                    “We are committed to establishing and maintaining long-term
                    relationships with our employees, clients, and
                    subcontractors/suppliers”
                  </p>
                </div>
              </div>
              <div class="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                <p class="leading-relaxed text-lg mb-4">
                  To support and inspire local people by building a better
                  future with our innovative building solutions and high-quality
                  materials, making a difference to their everyday lives. With
                  our full commitment to ensure we act in an environmentally and
                  sustainable responsible manner to minimise the impact of all
                  our operations on the environment.
                </p>
                <a class="text-indigo-500 inline-flex items-center">
                  Learn More
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
  </section>*/}
      <div className="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-4">
        <div className=" shadow-xl sm:p-16  bg-white p-5 rounded-xl bg-opacity-30 backdrop-filter backdrop-blur-xl">
          <div className="flex flex-col lg:flex-row">
            <div className="mb-6 lg:mb-2 lg:w-1/2 lg:pr-5">
              <p class="text-reds md:text-2xl text-2xl xl:text-2xl font-curs mb-2 md:mb-3">
                Introducing
              </p>
              <h2 className="font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                Quality construction services
                <br className="hidden md:block" /> you've always{" "}
                <span className="inline-block text-deep-purple-accent-400">
                  thought about.
                </span>
              </h2>
            </div>
            <div className="lg:w-1/2">
              <p className="mb-4 text-base text-gray-700">
                To support and inspire local people by building a better future
                with our innovative building solutions and high-quality
                materials, making a difference to their everyday lives. With our
                full commitment to ensure we act in an environmentally and
                sustainable responsible manner to minimise the impact of all our
                operations on the environment.
              </p>
              <a
                href="/blog"
                aria-label=""
                className="inline-flex items-center font-semibold transition-colors duration-200 text-reds hover:text-lightBlue"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileFile;
