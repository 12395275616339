import "./App.css";
import GallerySite from "./RouterFolder/GallerySite";
import MainSite from "./RouterFolder/MainSite";
import BlogSite from "./RouterFolder/BlogSite";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import HeaderTop from "./HeaderTop/HeaderTop";
import Footer from "./Footer/Footer";

function App() {
  return (
    <div className="bg-gradient-to-r from-indigo-200 via-red-200 to-yellow-100 ">
      <HeaderTop />
      <Routes>
        <Route path="/" element={<MainSite />} />
        <Route path="/gallery" element={<GallerySite />} />
        <Route path="/blog" element={<BlogSite />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
