import React from "react";

const GalleryPics = () => {
  return (
    <>
      <div className=" px-4 py-4 mx-auto sm:max-w-xl md:max-w-6xl lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-4">
        <div className="shadow-xl  sm:p-16  bg-white p-5 rounded-xl bg-opacity-30 ">
          <div className="flex flex-col lg:flex-row">
            <div className="mb-6 lg:mb-2 lg:w-1/2 lg:pr-5">
              <p class="text-reds md:text-2xl text-2xl xl:text-2xl font-curs mb-2 md:mb-3">
                Introducing
              </p>
              <h2 className="font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
                Quality construction services
                <br className="hidden md:block" /> you've always{" "}
                <span className="inline-block text-deep-purple-accent-400">
                  thought about.
                </span>
              </h2>
            </div>
            <div className="lg:w-1/2">
              <p className="mb-4 text-base text-gray-700">
                Our company founded and successfully building hundreds of
                Residential houses, farm house, commercial & renovation
                buildings by Mr. Rajavel Kuppusmy in the year of 1991. From 2015
                company was leading by Er. Kumaran Rajavel, B.Tech.
              </p>
              <a
                href="/blog"
                aria-label=""
                className="inline-flex items-center font-semibold transition-colors duration-200 text-reds hover:text-lightBlue"
              >
                Learn more
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="max-w-screen-2xl mt-4 px-4 md:px-8 mx-auto">
        <div class=" py-6 sm:py-8 lg:py-12">
          <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
            <h2 class="text-gray-800 text-2xl lg:text-3xl font-bold mb-4 md:mb-6">
              Amutha Kaatru Valagam
            </h2>

            <p class="max-w-screen-md text-gray-500 md:text-lg">
              Location: Vadalure, Cuddalore District <br /> Client Name: Mr.
              Senthil USA,
              <br /> Plot Area: 6500 Sq.ft <br />
              Build up Area: 4500 Sq.ft
            </p>
          </div>
        </div>
        <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 md:gap-6 xl:gap-8 mb-4 md:mb-8">
          <a
            href="#"
            class="group h-48 md:h-80 flex items-end bg-gray-100 overflow-hidden rounded-lg shadow-lg relative"
          >
            <img
              src={require("../imgFiles/PHOTO-2022-12-07-02-04-09.jpg")}
              loading="lazy"
              class="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200"
            />

            <div class="bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50 absolute inset-0 pointer-events-none"></div>
          </a>

          <a
            href="#"
            class="group h-48 md:h-80 flex items-end bg-gray-100 overflow-hidden rounded-lg shadow-lg relative"
          >
            PHOTO-2022-12-07-02-04-08
            <img
              src={require("../imgFiles/PHOTO-2022-12-07-02-04-08.jpg")}
              loading="lazy"
              alt="Photo by Magicle"
              class="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200"
            />
            <div class="bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50 absolute inset-0 pointer-events-none"></div>
          </a>

          <a
            href="#"
            class="group h-48 md:h-80 flex items-end bg-gray-100 overflow-hidden rounded-lg shadow-lg relative"
          >
            <img
              src={require("../imgFiles/PHOTO-2022-12-07-02-04-01.jpg")}
              loading="lazy"
              alt="Photo by Martin Sanchez"
              class="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200"
            />

            <div class="bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50 absolute inset-0 pointer-events-none"></div>
          </a>

          <a
            href="#"
            class="group h-48 md:h-80 flex items-end bg-gray-100 overflow-hidden rounded-lg shadow-lg relative"
          >
            <img
              src={require("../imgFiles/PHOTO-2022-12-07-02-04-02_1.jpg")}
              loading="lazy"
              alt="Photo by Lorenzo Herrera"
              class="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200"
            />

            <div class="bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50 absolute inset-0 pointer-events-none"></div>
          </a>
        </div>
        <div class=" py-6 sm:py-8 lg:py-12">
          <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
            <h2 class="text-gray-800 text-2xl lg:text-3xl font-bold mb-4 md:mb-6">
              Leaf Bay
            </h2>

            <p class="max-w-screen-md text-gray-500 md:text-lg">
              Location: Mugilivakkam, Chennai <br /> Client Name: Mr. Subham
              Kumar,
              <br /> Plot Area : 2000 Sq.Ft <br />
              Build Up Area: 3000 Sq.Ft
            </p>
          </div>
        </div>
        <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 md:gap-6 xl:gap-8 mb-4 md:mb-8">
          <a
            href="#"
            class="group h-48 md:h-80 flex items-end bg-gray-100 overflow-hidden rounded-lg shadow-lg relative"
          >
            <img
              src={require("../imgFiles/PHOTO-2022-12-07-02-04-10.jpg")}
              loading="lazy"
              class="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200"
            />

            <div class="bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50 absolute inset-0 pointer-events-none"></div>
          </a>

          <a
            href="#"
            class="group h-48 md:h-80 flex items-end bg-gray-100 overflow-hidden rounded-lg shadow-lg relative"
          >
            <img
              src={require("../imgFiles/WhatsApp Image 2022-11-28 at 7.20.55 PM.jpeg")}
              loading="lazy"
              alt="Photo by Magicle"
              class="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200"
            />

            <div class="bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50 absolute inset-0 pointer-events-none"></div>
          </a>

          <a
            href="#"
            class="group h-48 md:h-80 flex items-end bg-gray-100 overflow-hidden rounded-lg shadow-lg relative"
          >
            <img
              src={require("../imgFiles/WhatsApp Image 2022-11-28 at 7.20.58 PM.jpeg")}
              loading="lazy"
              alt="Photo by Martin Sanchez"
              class="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200"
            />

            <div class="bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50 absolute inset-0 pointer-events-none"></div>
          </a>

          <a
            href="#"
            class="group h-48 md:h-80 flex items-end bg-gray-100 overflow-hidden rounded-lg shadow-lg relative"
          >
            <img
              src={require("../imgFiles/WhatsApp Image 2022-11-28 at 7.20.57 PM.jpeg")}
              loading="lazy"
              alt="Photo by Lorenzo Herrera"
              class="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200"
            />

            <div class="bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50 absolute inset-0 pointer-events-none"></div>
          </a>
        </div>
      </div>
    </>
  );
};

export default GalleryPics;
