import React from "react";

const Gallery = () => {
  return (
    <div>
      <div class="max-w-screen-2xl px-4 pt-4 md:px-8 mx-auto">
        <h2 class="text-a-800 text-2xl lg:text-3xl font-bold mb-4 md:mb-6">
          Customise your design
        </h2>

        <p class="max-w-screen-md text-gray-500 md:text-lg">
          We work with expert construction professionals who are with you every
          step of the way, managing timelines and budgets exactly as per plan.
          we believe in being completely transparent about pricing and offer
          customizable packages to fit your budget.
        </p>
      </div>
      <div class="  bg-gradient-to-r from-indigo-200 via-red-200 to-yellow-100 py-6 sm:py-8 lg:py-12">
        <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
          <div class="grid grid-cols-2 sm:grid-cols-3 gap-4 md:gap-6 xl:gap-8">
            <a
              href="/gallery"
              class="group h-48 md:h-80 flex items-end bg-gray-100 overflow-hidden rounded-lg shadow-lg relative"
            >
              <img
                src={require("../imgFiles/PHOTO-2022-12-07-02-04-02.jpg")}
                loading="lazy"
                alt="Photo by Minh Pham"
                class="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200"
              />

              <div class="bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50 absolute inset-0 pointer-events-none"></div>

              <span class="inline-block text-white text-sm md:text-lg relative ml-4 md:ml-5 mb-3">
                Amutha Kaatru Valagam
              </span>
            </a>

            <a
              href="/gallery"
              class="group h-48 md:h-80 md:col-span-2 flex items-end bg-gray-100 overflow-hidden rounded-lg shadow-lg relative"
            >
              <img
                src={require("../imgFiles/WhatsApp Image 2022-11-28 at 7.20.58 PM.jpeg")}
                loading="lazy"
                alt="Photo by Magicle"
                class="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200"
              />

              <div class="bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50 absolute inset-0 pointer-events-none"></div>

              <span class="inline-block text-white text-sm md:text-lg relative ml-4 md:ml-5 mb-3">
                Leaf Bay
              </span>
            </a>

            <a
              href="/gallery"
              class="group h-48 md:h-80 md:col-span-2 flex items-end bg-gray-100 overflow-hidden rounded-lg shadow-lg relative"
            >
              <img
                src={require("../imgFiles/PHOTO-2022-12-07-02-04-03.jpg")}
                loading="lazy"
                alt="Photo by Martin Sanchez"
                class="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200"
              />

              <div class="bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50 absolute inset-0 pointer-events-none"></div>
            </a>

            <a
              href="/gallery"
              class="group h-48 md:h-80 flex items-end bg-gray-100 overflow-hidden rounded-lg shadow-lg relative"
            >
              <img
                src={require("../imgFiles/WhatsApp Image 2022-11-28 at 7.20.55 PM.jpeg")}
                loading="lazy"
                alt="Photo by Lorenzo Herrera"
                class="w-full h-full object-cover object-center absolute inset-0 group-hover:scale-110 transition duration-200"
              />

              <div class="bg-gradient-to-t from-gray-800 via-transparent to-transparent opacity-50 absolute inset-0 pointer-events-none"></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
